<template>
  <div :class="$style.purchase">
    <span>¥{{ price }}</span>
    <div :class="$style.pay" @click="handlePay">
      {{ stock ? "购买" : "售罄" }}
    </div>
    <RealVerified
      :showRealVerified.sync="showRealVerified"
      @refresh="handleSuccessReal"
    />
  </div>
</template>

<script>
import { getStorage } from "@/utils/util";
import RealVerified from "@/components/RealVerified";
export default {
  components: {
    RealVerified,
  },
  props: ["price", "stock"],
  data() {
    return {
      showRealVerified: false,
    };
  },
  methods: {
    handlePay() {
      if (!this.stock) return;
      const login = getStorage("token");
      const info = getStorage("user_info") || {};
      const haveInfo = info.name && info.id_number;
      // "未登陆", 2: "已经登录，但是没有身份信息",3: "有身份信息可以正常购买",
      if (!login) {
        this.$dialog
          .confirm({
            title: "",
            message: "您还未登录，请登录后进行购买",
            confirmButtonText: "去登录",
          })
          .then(() => {
            this.$router.push("/login");
          })
          .catch(() => {});
        return;
      }
      if (login && !haveInfo) {
        this.showRealVerified = true;
        return;
      }
      if (login && haveInfo) {
        this.$emit("handlePay");
        return;
      }
    },
    handleSuccessReal() {
      setTimeout(() => {
        this.$emit("handlePay");
      }, 500);
    },
  },
};
</script>

<style lang="scss" module>
.purchase {
  width: 311px;
  height: 44px;
  line-height: 44px;
  background: #212121;
  border-radius: 28px;
  position: fixed;
  bottom: 34px;
  left: 32px;
  font-size: 16px;
  font-weight: 500;
  padding-left: 16px;
  display: flex;
  justify-content: space-between;
  .pay {
    height: 100%;
    width: 88px;
    background-color: #fff;
    color: #212121;
    border-radius: 0 28px 28px 0;
    text-align: center;
  }
}
</style>
