// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Pay_purchase_1nZOp{width:8.29333rem;height:1.17333rem;line-height:1.17333rem;background:#212121;border-radius:.74667rem;position:fixed;bottom:.90667rem;left:.85333rem;font-size:.42667rem;font-weight:500;padding-left:.42667rem;display:flex;justify-content:space-between}.Pay_purchase_1nZOp .Pay_pay_1jZJu{height:100%;width:2.34667rem;background-color:#fff;color:#212121;border-radius:0 .74667rem .74667rem 0;text-align:center}", ""]);
// Exports
exports.locals = {
	"purchase": "Pay_purchase_1nZOp",
	"pay": "Pay_pay_1jZJu"
};
module.exports = exports;
